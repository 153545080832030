import { Card, ToggleButton } from 'react-bootstrap';

function DayCard({ day, canRehearse, onToggle }) {
    const cardClassName = canRehearse ? 'mb-4 bg-success' : 'mb-4 bg-danger';

    return (
        <Card className={cardClassName}>
            <Card.Body>
                <Card.Title>{day}</Card.Title>
                <Card.Text>{day}ar är {canRehearse ? '' : 'inte'} möjliga att repa på.</Card.Text>
                <ToggleButton
                    variant="secondary"
                    checked={canRehearse}
                    onClick={onToggle}>
                    {canRehearse ? 'Ta bort repmöjlighet' : 'Lägg till repmöjlighet'}
                </ToggleButton>
            </Card.Body>
        </Card>
    );
}

export default DayCard;

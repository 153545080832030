import axios from 'axios';

const API_BASE = "/api";

const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
};

const handleRequest = async request => {
    try {
        const response = await axios(request);
        checkStatus(response);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createApiService = (endpoint, customMethods = {}) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true
    };

    const methods = {
        async getAll() {
            const request = {
                url: `${API_BASE}/${endpoint}`, method: 'get', config,
            };
            return handleRequest(request);
        },

        async add(item) {
            const request = {
                url: `${API_BASE}/${endpoint}`, method: 'post', data: item, config,
            };
            return handleRequest(request);
        },

        async edit(item) {
            const request = {
                url: `${API_BASE}/${endpoint}`, method: 'put', data: item, config,
            };
            return handleRequest(request);
        },

        async delete(id) {
            const request = {
                url: `${API_BASE}/${endpoint}/${id}`, method: 'delete', config,
            };
            await handleRequest(request);
        },

        ...customMethods,
    };

    return methods;
};

export const rehearsalsService = createApiService("rehearsals");
export const peopleService = createApiService("people");
export const configService = createApiService("configuration");
export const rehearsalGroupService = createApiService("rehearsals/group", {
    async switchRehearsalGroups(id1, id2) {
        const request = {
            url: `${API_BASE}/rehearsals/group/switch`, method: 'post', data: { rehearsalGroup1: id1, rehearsalGroup2: id2 }
        };
        return handleRequest(request);
    },
    async copyRehearsalGroup(groupId) {
        const request = {
            url: `${API_BASE}/rehearsals/group/copy`, method: 'post', data: { rehearsalGroupId: groupId }
        };
        return handleRequest(request);
    },
});

export const scheduleService = {
    async fetch(operationId) {
        const request = {
            url: `${API_BASE}/schedule?operationId=${operationId}`, method: 'get',
        };
        return handleRequest(request);
    },
    async cancel(operationId) {
        const request = {
            url: `${API_BASE}/schedule/cancel`, method: 'post', data: { operationId: operationId },
        };
        return handleRequest(request);
    }
};

import Modal from 'react-bootstrap/Modal';
import React from "react";
import Button from 'react-bootstrap/Button';
import SimpleNameForm from '../forms/SimpleNameForm';

export default function EditPersonModal({ modalShow, onHide, editPerson, personToEdit }) {
  const handleSubmit = (name) => {
    editPerson({ name: name, id: personToEdit.id });
    onHide();
  };

  return (
    <Modal
      show={modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Redigera {personToEdit.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SimpleNameForm onSubmit={handleSubmit} initialValue={personToEdit.name}></SimpleNameForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>Stäng</Button>
      </Modal.Footer>
    </Modal>
  );
}
import React from 'react';
import usePeople from '../hooks/usePeople';
import useRehearsals from '../hooks/useRehearsals';
import useSchedule from '../hooks/useSchedule';
import useConfig from '../hooks/useConfig';

const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
  const rehearsalsData = useRehearsals();
  const peopleData = usePeople(rehearsalsData.updatePersonInRehearsalUnits);
  const scheduleData = useSchedule();
  const configData = useConfig();

  const clearData = () => {
    peopleData.clearPeople();
    rehearsalsData.clearRehearsalGroups();
    configData.clearConfig();
    scheduleData.clearSchedule();
    scheduleData.cancelScheduleRequest();
  }

  const value = {
    clearData,
    ...configData,
    ...peopleData,
    ...rehearsalsData,
    ...scheduleData,
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;

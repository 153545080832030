import React from 'react';

export default function PeopleTable ({ people, showDeleteConfirm, showPerson }) {
    return (
    <table className="table table-striped" aria-labelledby="tableLabel">
        <thead>
            <tr>
                <th>Namn: </th>
                <th />
            </tr>
        </thead>
        <tbody>
            {people.map((person) => (
                <tr key={person.id}>
                    <td>{person.name}</td>
                    <td className="cursorPointer" onClick={() => showDeleteConfirm(person.id)}><i>Ta bort</i></td>
                    <td className="cursorPointer" onClick={() => showPerson(person)}><i>Redigera</i></td>
                </tr>
            ))}
        </tbody>
    </table>
)};
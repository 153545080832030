import React, { useContext, useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DataContext from '../contexts/DataContext';
import DayCard from './configurationComponents/DayCard';
import _ from 'lodash';

export default function Configuration() {
    const { config, updateConfig, configLoading } = useContext(DataContext);
    const [formConfig, setFormConfig] = useState({
        maxTimeWithoutBreak: 0,
        maxHoursPerDay: 0,
        breakDuration: 0,
        startTime: new Date(),
        maxScheduleGenerations: 1000,
        rehearsalDayRuleSets: Array(7).fill(false)
    });
    const [error, setError] = useState(null);

    const daysOfWeek = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];

    useEffect(() => {
        if (config && config.rehearsalDayRuleSets) {
            const initialFormConfig = {
                ...config,
                rehearsalDayRuleSets: config.rehearsalDayRuleSets.reduce((acc, curr) => {
                    acc[curr.dayOfWeek] = curr.canRehearse;
                    return acc;
                }, {}),
            };
            setFormConfig(initialFormConfig);
        }
    }, [config]);

    const handleChange = e => {
        setFormConfig({
            ...formConfig,
            [e.target.name]: e.target.value
        });
    };

    const debouncedSetMaxScheduleGenerations = _.debounce(value => {
        setFormConfig(prevFormConfig => ({
            ...prevFormConfig,
            maxScheduleGenerations: value
        }));
    }, 0); //Odd.. men blir error i console annars när man rör sig i slidern.

    const handleMaxScheduleGenerationsChange = e => {
        const value = parseInt(e.target.value, 10);
        debouncedSetMaxScheduleGenerations(value);
    };

    const handleDateChange = date => {
        setFormConfig({
            ...formConfig,
            startTime: toLocalISOString(date)
        });
    };

    const handleDayToggle = dayIndex => e => {
        setFormConfig({
            ...formConfig,
            rehearsalDayRuleSets: {
                ...formConfig.rehearsalDayRuleSets,
                [dayIndex]: !formConfig.rehearsalDayRuleSets[dayIndex]
            }
        });
    };

    const maxScheduleGenerationsLabel = () => {
        const { maxScheduleGenerations } = formConfig;
        const formattedMaxScheduleGenerations = maxScheduleGenerations.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (maxScheduleGenerations <= 5000) return `${formattedMaxScheduleGenerations} (Väldigt snabb)`;
        if (maxScheduleGenerations <= 25000) return `${formattedMaxScheduleGenerations} (Snabb)`;
        if (maxScheduleGenerations <= 74999) return `${formattedMaxScheduleGenerations} (Balanserad)`;
        if (maxScheduleGenerations <= 89999) return `${formattedMaxScheduleGenerations} (Noggrann)`;
        return `${formattedMaxScheduleGenerations} (Väldigt noggrann)`;
    };


    function toLocalISOString(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
        const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, -1);
        return localISOTime;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const finalFormConfig = {
                ...formConfig,
                rehearsalDayRuleSets: Object.entries(formConfig.rehearsalDayRuleSets).map(([dayOfWeek, canRehearse]) => ({ dayOfWeek: Number(dayOfWeek), canRehearse })),
            };
            await updateConfig(finalFormConfig)
        } catch (error) {
            console.error(error);
            setError('Failed to update configuration');
        }
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else {
        return (
            <div>
                <h3>Konfiguration</h3>
                {configLoading ? (
                    <p>
                        <em>Laddar...</em>
                    </p>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formMaxTimeWithoutBreak">
                            <Form.Label>Max antal minuter utan paus:</Form.Label>
                            <Form.Control type="number" name="maxTimeWithoutBreak" value={formConfig.maxTimeWithoutBreak} onChange={handleChange} required />
                        </Form.Group>

                        <Form.Group controlId="formMaxHoursPerDay">
                            <Form.Label>Max antal timmar per dag:</Form.Label>
                            <Form.Control type="number" name="maxHoursPerDay" value={formConfig.maxHoursPerDay} onChange={handleChange} required />
                        </Form.Group>

                        <Form.Group controlId="formBreakDuration">
                            <Form.Label>Pauslängd:</Form.Label>
                            <Form.Control type="number" name="breakDuration" value={formConfig.breakDuration} onChange={handleChange} required />
                        </Form.Group>

                        <Form.Group controlId="formStartTime">
                            <Form.Label>Starttid:</Form.Label>
                            <DateTimePicker name="startTime" onChange={handleDateChange} value={new Date(formConfig.startTime)} required />
                        </Form.Group>

                        <Form.Group controlId="formMaxScheduleGenerations">
                            <Form.Label>Max antal schemagenereringar per repgrupp (ju högre desto längre tid kan det ta att generera ett schema):</Form.Label>
                            <Form.Control type="range" min="1000" max="100000" name="maxScheduleGenerations" value={formConfig.maxScheduleGenerations} onChange={handleMaxScheduleGenerationsChange} required />
                            <p>{maxScheduleGenerationsLabel()}</p>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Spara
                        </Button>

                        <h4>Dagar för repetition</h4>
                        <p><i>(Glöm inte spara vid förändringar)</i></p>
                        <Row>
                            {
                                daysOfWeek.slice(1).concat(daysOfWeek[0]).map((day, index) => (
                                    <Col md={4} key={day}>
                                        <DayCard
                                            day={day}
                                            canRehearse={formConfig.rehearsalDayRuleSets[(index + 1) % 7]}
                                            onToggle={handleDayToggle((index + 1) % 7)}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>

                        <Button variant="primary" type="submit">
                            Spara
                        </Button>
                    </Form>
                )}
            </div>
        )
    }
}
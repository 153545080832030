import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ExpandMore } from '@mui/icons-material';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={1} {...props} />
))({
    border: '1px solid rgba(0, 0, 0, .125)',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    }
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMore sx={{ fontSize: '1.4rem' }} />}
      {...props}
    />
))({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row',
    '&:hover': {backgroundColor: 'rgba(0, 0, 0, .08)'},
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: '8px',
    },
    cursor: 'default'
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: '16px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: 'rgba(0, 0, 0, .01)',
});

export default function useAccordionStyles() {
    return { Accordion, AccordionSummary, AccordionDetails };
}

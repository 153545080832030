import React, { useEffect, useContext, useReducer } from 'react';
import { Form, Button, ListGroup, Row, Col } from 'react-bootstrap';
import DataContext from '../../contexts/DataContext';

const initialState = {
  name: '',
  duration: 0,
  selectedPeople: [],
  availablePeople: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_DURATION':
      return { ...state, duration: action.payload };
    case 'SET_SELECTED_PEOPLE':
      return { ...state, selectedPeople: action.payload };
    case 'SET_AVAILABLE_PEOPLE':
      return { ...state, availablePeople: action.payload };
    case 'ADD_PERSON':
      return {
        ...state,
        selectedPeople: [...state.selectedPeople, action.payload],
        availablePeople: state.availablePeople.filter(person => person.id !== action.payload.id),
      };
    case 'REMOVE_PERSON':
      return {
        ...state,
        selectedPeople: state.selectedPeople.filter(person => person.id !== action.payload.id),
        availablePeople: [...state.availablePeople, action.payload],
      };
    default:
      return state;
  }
};

export default function RehearsalUnitForm({ onSubmit, initialValues, rehearsalGroup, editMode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Use PeopleContext
  const { people } = useContext(DataContext);

  // Remove selectedPeople from availablePeople when initialValues or people change
  useEffect(() => {
    if (initialValues) {
      const selectedIds = initialValues.people.map(p => p.id);
      dispatch({ type: 'SET_NAME', payload: initialValues.name || "" });
      dispatch({ type: 'SET_DURATION', payload: initialValues.duration || 0 });
      dispatch({ type: 'SET_SELECTED_PEOPLE', payload: initialValues.people });
      dispatch({ type: 'SET_AVAILABLE_PEOPLE', payload: people.filter(p => !selectedIds.includes(p.id)) });
    } else {
      dispatch({ type: 'SET_AVAILABLE_PEOPLE', payload: people });
    }
  }, [initialValues, people]);

  const handleNameChange = (e) => {
    dispatch({type: 'SET_NAME', payload: e.target.value});
  };

  const handleDurationChange = (e) => {
    if (e.target.value >= 0) {
      dispatch({type: 'SET_DURATION', payload: e.target.value});
    }
  };

  const addPerson = (id) => {
    const personToAdd = state.availablePeople.find(person => person.id === id);
    dispatch({ type: 'SET_SELECTED_PEOPLE', payload: [...state.selectedPeople, personToAdd] });
    dispatch({ type: 'SET_AVAILABLE_PEOPLE', payload: state.availablePeople.filter(person => person.id !== id) });
  };

  const removePerson = (id) => {
    const personToRemove = state.selectedPeople.find(person => person.id === id);
    dispatch({type: 'SET_AVAILABLE_PEOPLE', payload: [...state.availablePeople, personToRemove]})
    dispatch({type: 'SET_SELECTED_PEOPLE', payload: state.selectedPeople.filter(person => person.id !== id)})
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, duration, selectedPeople } = state;
    if (name.trim() && duration > 0 && selectedPeople.length > 0) {
      const formData = {
        name, duration, peopleIds: selectedPeople.map(person => person.id)
      };
      if (!editMode) {
        formData.rehearsalGroupId = rehearsalGroup.id;
      }
      onSubmit(formData);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="name">
          <Form.Label>Namn:</Form.Label>
          <Form.Control
            type="text"
            value={state.name}
            maxLength={30}
            onChange={handleNameChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="duration">
          <Form.Label>Längd:</Form.Label>
          <Form.Control
            type="number"
            min="0"
            value={state.duration}
            onChange={handleDurationChange}
            required
          />
        </Form.Group>

        {rehearsalGroup &&
        <Form.Group>
         <Form.Label>Repgrupp: <b> {rehearsalGroup.name}</b></Form.Label>
        </Form.Group>}
        <hr/>
        <h5>Tillgängliga personer</h5>
        <ListGroup>
          {state.availablePeople.map((person) => (
            <ListGroup.Item key={person.id}>
              <Row>
                <Col>{person.name}</Col>
                <Col>
                  <Button variant="primary" onClick={() => addPerson(person.id)} className="ms-2">
                    +
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <h5>Valda personer</h5>
        <ListGroup>
          {state.selectedPeople.map((person) => (
            <ListGroup.Item key={person.id}>
              <Row>
                <Col>{person.name}</Col>
                <Col>
                  <Button variant="danger" onClick={() => removePerson(person.id)} className="ms-2">
                    -
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <Button variant="primary" type="submit" className="mt-3">
          Skicka
        </Button>
      </Form>
    </div>
  );
}

import React, { useContext } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

export default function Home() {
  const { user } = useContext(UserContext);

  return (
    <Container className="mt-3">
      <h1 className="text-center mb-4">Välkommen till den automatiserade schemaläggaren!</h1>
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Generera ett schema</Card.Title>
              <Card.Text>
                Skapa ett helt nytt schema baserat på reptillfällena som finns tillagda med bara ett klick!
              </Card.Text>
              {user && (
                <>
                  <NavLink to="/schedule">
                    <Button variant="primary">Gå till</Button>
                  </NavLink>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Lägga till personer eller reptillfällen</Card.Title>
              <Card.Text>
                Enkelt lägga till nya personer eller reptillfällen till ditt projekt.
              </Card.Text>
              {user && (
                <>
                  <NavLink to="/people">
                    <Button variant="primary" className='me-2'>Personer</Button>
                  </NavLink>
                  <NavLink to="/rehearsals">
                    <Button variant="primary">Reptillfällen</Button>
                  </NavLink>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Ändra schemainställningar</Card.Title>
              <Card.Text>
                Ändra värden såsom längd mellan pauser, pauslängd och startdatum.
              </Card.Text>
              {user && (
                <>
                  <NavLink to="/configuration">
                    <Button variant="primary">Gå till</Button>
                  </NavLink>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h2>Kommande planerade funktioner </h2>
          <p><i>(om det någonsin kommer finnas tid för det...)</i></p>
          <ul>
            <li>Lägga in mer specifika tidpunkter som går att repa på</li>
            <li>Lägga in tider en person inte kan repa</li>
            <li>Göra prioriteringen redigerbar (om någon person är okej med en kort dag men inte en annan exempelvis)</li>
            <li>Lägga in möjlighet att specificera om ett specifikt reptillfälle måste repas före ett annat (inom samma repgrupp)</li>
            <li>Inloggning och redigerbart</li>
            <li>Personliga repscheman som autoskapas till PDF.</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

import { useState, useEffect, useContext } from 'react';
import { rehearsalGroupService, rehearsalsService } from '../services/ApiService';
import { toast } from 'react-toastify';
import { UserContext } from '../contexts/UserContext';
import { handleError } from '../services/errorHandler';

const useRehearsals = () => {
    const [rehearsalGroups, setRehearsalGroups] = useState([]);
    const [rehearsalGroupsLoading, setRehearsalGroupsLoading] = useState(false);
    
    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            setRehearsalGroupsLoading(true);
            try {
                if (!user) return;
                setRehearsalGroups(await rehearsalGroupService.getAll());
            } catch (error) {
                console.error(error);
                toast.error(error.message);
            } finally {
                setRehearsalGroupsLoading(false);
            }
        };

        fetchData();
    }, [user])

    const addRehearsalUnit = async (rUnit) => {
        try {
            const data = await rehearsalsService.add(rUnit);
            setRehearsalGroups(prevRehearsalGroups => {
                return prevRehearsalGroups.map(group => {
                    if (group.id === data.rehearsalGroup.id) {
                        return {
                            ...group,
                            rehearsalUnits: [...group.rehearsalUnits, data]
                        }
                    } else {
                        return group;
                    }
                })
            });
            toast.success(rUnit.name + ' har lagts till i databasen.');
        } catch (error) {
            handleError(error);
        }
    };

    const addRehearsalGroup = async (name) => {
        try {
            const data = await rehearsalGroupService.add({ name: name });
            setRehearsalGroups((prevRehearsalGroups) => [...prevRehearsalGroups, data]);
            toast.success(name + ' har skapats.');
        } catch (error) {
            handleError(error);
        }
    }

    const editRehearsalUnit = async (rUnit) => {
        try {
            const data = await rehearsalsService.edit(rUnit);
            setRehearsalGroups(prevRehearsalGroups => {
                return prevRehearsalGroups.map(group => {
                    if (group.rehearsalUnits && group.rehearsalUnits.some(s => s.id === rUnit.id)) {
                        return {
                            ...group,
                            rehearsalUnits: group.rehearsalUnits.map(s => s.id === rUnit.id ? data : s)
                        }
                    } else {
                        return group;
                    }
                })
            });
            toast.success(rUnit.name + ' har uppdaterats.');
        } catch (error) {
            handleError(error);
        }
    };

    const updatePersonInRehearsalUnits = (updatedPerson) => {
        setRehearsalGroups((prevRehearsalGroups) =>
          prevRehearsalGroups.map((group) => ({
            ...group,
            rehearsalUnits: group.rehearsalUnits.map((unit) => ({
              ...unit,
              people: unit.people.map((person) =>
                person.id === updatedPerson.id ? updatedPerson : person
              ),
            })),
          }))
        );
      };
      

    const editRehearsalGroup = async (group) => {
        try {
            const data = await rehearsalGroupService.edit(group);
            setRehearsalGroups((prevGroup) =>
                prevGroup.map((grp) => {
                    if (grp.id === group.id) {
                        return { ...grp, name: data.name };
                    } else {
                        return grp;
                    }
                })
            );
            toast.success(group.name + ' har uppdaterats.');
        } catch (error) {
            handleError(error);
        }
    };

    const copyRehearsalGroup = async (id) => {
        try {
            const response = await rehearsalGroupService.copyRehearsalGroup(id)
            toast.success('Repgruppen har kopierats.')
            setRehearsalGroups((prevGroups) => [...prevGroups, response]);

        } catch (error) {
            handleError(error, "Något gick fel vid kopieringen av repgruppen.");
        }
    };

    const deleteRehearsalGroup = async (id) => {
        try {
            await rehearsalGroupService.delete(id);

            setRehearsalGroups((prevRehearsalGroups) =>
                prevRehearsalGroups = prevRehearsalGroups.filter((grp) => grp.id !== id)
            );

            toast.info('Repgruppen har tagits bort.');

        } catch (error) {
            handleError(error, "Något gick fel vid när repgruppen skulle tas bort.");
        }
    }

    const switchRehearsalGroups = async (id1, id2) => {
        try {
            const response = await rehearsalGroupService.switchRehearsalGroups(id1, id2);

            if (response && response.groups) {
                let updatedGroups = [...rehearsalGroups];

                response.groups.forEach(group => {
                    let groupIndex = updatedGroups.findIndex(g => g.id === group.id);

                    if (groupIndex !== -1) {
                        updatedGroups[groupIndex] = { ...updatedGroups[groupIndex], order: group.order };
                    }
                });

                // Sort the groups by order
                updatedGroups.sort((a, b) => a.order - b.order);

                setRehearsalGroups(updatedGroups);
            }

            toast.success('Repgrupp flyttad.');
        } catch (error) {
            handleError(error);
        }
    };

    const deleteRehearsalUnit = async (rUnitId) => {
        try {
            await rehearsalsService.delete(rUnitId);
            setRehearsalGroups(prevRehearsalGroups => {
                return prevRehearsalGroups.map(group => {
                    if (group.rehearsalUnits && group.rehearsalUnits.some(rUnit => rUnit.id === rUnitId)) {
                        return {
                            ...group,
                            rehearsalUnits: group.rehearsalUnits.filter(rUnit => rUnit.id !== rUnitId)
                        }
                    } else {
                        return group;
                    }
                })
            });
            toast.info('Reptillfället har tagits bort.');
        } catch (error) {
            handleError(error, "Något gick fel när reptillfället skulle tas bort.")
        }
    };


    const clearRehearsalGroups = () => {
        setRehearsalGroups(null);
    }

    return {
        rehearsalGroups,
        rehearsalGroupsLoading,
        addRehearsalUnit,
        editRehearsalUnit,
        editRehearsalGroup,
        copyRehearsalGroup,
        addRehearsalGroup,
        deleteRehearsalGroup,
        deleteRehearsalUnit,
        switchRehearsalGroups,
        clearRehearsalGroups,
        updatePersonInRehearsalUnits
    }
}

export default useRehearsals
import React from "react";
import { Navigate } from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import People from "./components/People";
import Home from "./components/Home";
import Configuration from "./components/Configuration";
import Schedule from "./components/Schedule";
import RehearsalUnit from "./components/RehearsalUnit";

const AppRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/people",
    element: <ProtectedRoute element={<People />} />,
  },
  {
    path: "/rehearsals",
    element: <ProtectedRoute element={<RehearsalUnit />} />,
  },
  {
    path: "/schedule",
    element: <ProtectedRoute element={<Schedule />} />,
  },
  {
    path: "/configuration",
    element: <ProtectedRoute element={<Configuration />} />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export default AppRoutes;

import { useState, useEffect, useContext } from 'react';
import { peopleService } from '../services/ApiService';
import { toast } from 'react-toastify';
import { UserContext } from '../contexts/UserContext';
import { handleError } from '../services/errorHandler';

const usePeople = (updatePersonInRehearsalUnits) => {
    const [people, setPeople] = useState([]);
    const [peopleLoading, setPeopleLoading] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            setPeopleLoading(true);
            try {
                if (!user) return;
                setPeople(await peopleService.getAll());
            } catch (error) {
                handleError(error);
            } finally{
                setPeopleLoading(false);
            }
        };

        fetchData();
    }, [user]);

    const addPerson = async (name) => {
        try {
            const data = await peopleService.add({ name: name });
            setPeople((prevPeople) => [...prevPeople, data]);
            toast.success(name + ' har lagts till i databasen.');
        } catch (error) {
            handleError(error);
        }
    };

    const clearPeople = () => {
        setPeople(null);
    }

    const editPerson = async (person) => {
        try {
            const data = await peopleService.edit(person);
            setPeople((prevPeople) =>
                prevPeople.map((per) => (per.id === person.id ? data : per))
            );

            updatePersonInRehearsalUnits(data);

            toast.success(person.name + ' har uppdaterats.');
        } catch (error) {
            handleError(error);
        }
    };

    const deletePerson = async (personId) => {
        try {
            await peopleService.delete(personId);
            const name = people.find((x) => x.id === personId).name;
            toast.info(name + ' har tagits bort.');
            setPeople((prevPeople) =>
                prevPeople.filter((per) => per.id !== personId)
            );
        } catch (error) {
            handleError(error,"Något gick fel när personen skulle tas bort");
        }
    };

    return {
        people,
        peopleLoading,
        addPerson,
        editPerson,
        deletePerson,
        clearPeople
    };
};

export default usePeople;
import React, { useContext } from "react";
import DataContext from "../contexts/DataContext";
import AddRehearsalGroup from "./addComponents/AddRehearsalGroup";
import RehearsalUnitsTable from "./rehearsalComponents/RehearsalUnitsTable";

export default function RehearsalUnit() {
    const { rehearsalGroups, rehearsalUnitsLoading, addRehearsalGroup, } = useContext(DataContext);

    return (
        <div>
            <h1 id="tableLabel">Reptillfällen</h1>
            <p>Dessa repgrupper finns just nu tillagda.</p>
            <p><b>Repgrupp</b> innebär att de reptillfällen som finns i den tidigare repgruppen kommer schemaläggas före nästa. 
            <br/> Exempelvis kommer samtliga reptillfällen i Repgrupp 1 att läggas före Repgrupp 2 börjar schemaläggas.</p>
            {rehearsalUnitsLoading ? (
                <p>
                    <em>Laddar...</em>
                </p>
            ) : (
                <>
                    <RehearsalUnitsTable rehearsalGroups={rehearsalGroups} />
                    <hr/>
                    <AddRehearsalGroup addGroup={addRehearsalGroup} />
                </>
            )}
        </div>
    );
}
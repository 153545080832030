import { useState } from 'react';
import { scheduleService } from '../services/ApiService';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { handleError } from '../services/errorHandler';


let unmounted = false;


const useSchedule = () => {
    const [schedule, setSchedule] = useState([]);
    const [currentOperationId, setCurrentOperationId] = useState(null);
    const [scheduleLoading, setScheduleLoading] = useState(false);

    const fetchSchedule = async () => {
        const operationId = uuidv4();
        setCurrentOperationId(operationId);
        setSchedule(null);
        setScheduleLoading(true);
        try {
          const result = await scheduleService.fetch(operationId);
          setSchedule(result.scheduledRehearsalUnits);
          toast.success('Schema genererat.');
        } catch (error) {
          if (error.response.status === 499 && !unmounted) {
            toast.error('Schemagenerering avbruten av användaren.');
          } else if (!unmounted) {
            handleError(error);
          }
        } finally {
          setScheduleLoading(false);
          unmounted = false;
        }
      };
    
      const cancelScheduleRequest = () => {
        if (currentOperationId) {
          scheduleService.cancel(currentOperationId);
          setCurrentOperationId(null);
        }
          setSchedule(null);
          setScheduleLoading(false);    
      }

      const clearSchedule = () => {
        setSchedule(null);
        unmounted = true;
      }

      return {
        schedule, scheduleLoading, fetchSchedule, cancelScheduleRequest, clearSchedule
      }
}

export default useSchedule;
const API_BASE = "/api";

const AuthService = {
    async login(username, password) {
        const response = await fetch(`${API_BASE}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        // The server should now be returning the user's data in the response body
        const user = await response.json();
        return user;
    },

    async getUser() {
        const response = await fetch(`${API_BASE}/auth`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        if (response.status === 204) 
            return null; // or any other appropriate value

        const user = await response.json();
        return user;
    },

    async logout() {
        const response = await fetch(`${API_BASE}/auth/logout`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        return true;
    }
};

export default AuthService;

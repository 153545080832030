import React from "react";
import SimpleNameForm from "../forms/SimpleNameForm";

export default function AddPerson({ addPerson }) {
  const handleSubmit = (name) => {
    addPerson(name);
  };

  return (
    <div>
      <h4>Lägg till en ny person</h4>
      <SimpleNameForm onSubmit={handleSubmit} />
    </div>
  );
}

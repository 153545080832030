import React, { useState, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Form, Button, Row, Col } from 'react-bootstrap';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { handleLogin } = useContext(UserContext);

    const handleSubmit = async event => {
        event.preventDefault();
        const success = await handleLogin(username, password);
        if(success) {
            setUsername('');
            setPassword('');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col md={4}>
                    <Form.Group controlId="userName">
                        <Form.Label>Användarnamn: </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Användarnamn"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group>
                        <Form.Label>Lösenord: </Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Lösenord"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col className='d-flex justify-content-center' md={4}>
                    <Button variant="primary" className='ms-2' type="submit">Logga in</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default Login;
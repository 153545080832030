import { Modal, Button } from 'react-bootstrap';

export default function ConfirmModal({ show, handleClose, confirmAction, text }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Bekräfta</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Nej
        </Button>
        <Button variant="primary" onClick={confirmAction}>
          Ja
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
import Modal from 'react-bootstrap/Modal';
import React from "react";
import Button from 'react-bootstrap/Button';
import SimpleNameForm from '../forms/SimpleNameForm';

export default function EditGroupModal({ show, handleClose, editGroup, groupToEdit }) {
  const handleSubmit = (name) => {
    editGroup({ name: name, id: groupToEdit.id });
    handleClose();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Redigera {groupToEdit.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SimpleNameForm onSubmit={handleSubmit} initialValue={groupToEdit.name}></SimpleNameForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>Stäng</Button>
      </Modal.Footer>
    </Modal>
  );
}
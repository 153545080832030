import Modal from 'react-bootstrap/Modal';
import React from "react";
import Button from 'react-bootstrap/Button';
import RehearsalUnitForm from '../forms/RehearsalUnitForm';

export default function AddRehearsalUnitModal({ show, handleClose, addRehearsalUnit, rehearsalGroup }) {
  const handleSubmit = (rUnit) => {
    addRehearsalUnit({ ...rUnit });
    handleClose();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Skapa nytt reptillfälle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RehearsalUnitForm rehearsalGroup={rehearsalGroup} onSubmit={handleSubmit}></RehearsalUnitForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>Stäng</Button>
      </Modal.Footer>
    </Modal>
  );
}
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import AuthService from '../services/AuthService';

export const UserContext = React.createContext({
  user: null,
  setUser: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  const loggingOut = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
    toast.info("Du har loggats ut.");
    window.removeEventListener('unauthorized', loggingOut);
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await AuthService.getUser();
        if (!user) return;
        setUser(user);
        setIsAuthenticated(true);
        window.addEventListener('unauthorized', loggingOut);
      } catch (error) {
        console.error(error);
      } finally {
        setIsAuthChecked(true);
      }
    };

    checkUser();

    return () =>{
      window.removeEventListener('unauthorized', loggingOut);
    }
  }, [loggingOut]);

  const handleLogin = async (username, password) => {
    try {
      const user = await AuthService.login(username, password);
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
        toast.success("Inloggning lyckades.");
        window.addEventListener('unauthorized', loggingOut);
        return true;
      }
      return false;
    } catch (error) {
      toast.error("Du kunde inte logga in.");
      console.error(error);
      return false;
    }
  };

  const handleLogout = async () => {
    try {
      await AuthService.logout();
      loggingOut();
    } catch (error) {
      toast.error("Något gick fel vid utloggningen.");
    }
  }


  return (
    <UserContext.Provider value={{ user, setUser, handleLogin, handleLogout, isAuthenticated, isAuthChecked }}>
      {children}
    </UserContext.Provider>
  );
}


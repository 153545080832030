import { useState, useEffect, useContext } from 'react';
import { configService } from '../services/ApiService';
import { toast } from 'react-toastify';
import { UserContext } from '../contexts/UserContext';
import { handleError } from '../services/errorHandler';

const useConfig = () => {
  const [config, setConfig] = useState({ maxTimeWithoutBreak: "", maxHoursPerDay: "", breakDuration: "", startTime: new Date(2017, 0, 1, 22, 15) });
  const [configLoading, setConfigLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      setConfigLoading(true);
      try {
        if (!user) return;
        setConfig(await configService.getAll());
      } catch (error) {
        handleError(error);
      } finally {
        setConfigLoading(false);
      }
    }

    fetchData();
  }, [user])

  const updateConfig = async (newConfig) => {
    try {
      await configService.add(newConfig);
      setConfig(newConfig);
      toast.success('Konfigurationen har sparats');
    } catch (error) {
      handleError(error,'Något gick fel vid sparningen av konfigurationen.');
    }
  };

  const clearConfig = () => {
    setConfig({ maxTimeWithoutBreak: "", maxHoursPerDay: "", breakDuration: "", startTime: new Date(2017, 0, 1, 22, 15) });
  }

  return {
    config,
    configLoading,
    updateConfig,
    clearConfig,
  }
}

export default useConfig
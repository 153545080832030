import React, { useContext, useReducer } from "react";
import AddPerson from "./addComponents/AddPerson";
import EditPersonModal from "./modals/EditPersonModal";
import ConfirmModal from "./modals/shared/ConfirmModal";
import PeopleTable from './PeopleTable';
import DataContext from "../contexts/DataContext";

// Define the reducer
function modalReducer(state, action) {
    switch (action.type) {
        case 'SHOW_EDIT':
            return { ...state, show: 'edit', person: action.payload }
        case 'SHOW_DELETE':
            return { ...state, show: 'delete', person: action.payload }
        case 'CLOSE':
            return { ...state, show: 'none' }
        default:
            throw new Error();
    }
}

export default function People() {
    const { people, peopleLoading, addPerson, editPerson, deletePerson } = useContext(DataContext); // access the context

    const [modal, dispatch] = useReducer(modalReducer, { show: 'none', person:{} });

    const showDeleteConfirm = (personId) => {
        const person = people.find(p => p.id === personId);
        dispatch({ type: 'SHOW_DELETE', payload: person });
    };

    const confirmDelete = async () => {
        await deletePerson(modal.person.id);
        dispatch({ type: 'CLOSE' });
    };

    const showPerson = (person) => {
        dispatch({ type: 'SHOW_EDIT', payload: person });
    }

    // Render the component
    return (
        <div>
            <h1 id="tableLabel">Personer</h1>
            <p>Dessa personer finns just nu tillagda.</p>
            {peopleLoading ? (
                <p>
                    <em>Laddar...</em>
                </p>
            ) : (
                <>
                    <PeopleTable people={people} showDeleteConfirm={showDeleteConfirm} showPerson={showPerson} />
                    <AddPerson addPerson={addPerson} />
                </>
            )}

            <EditPersonModal
                modalShow={modal.show === 'edit'}
                onHide={() => dispatch({ type: 'CLOSE' })}
                personToEdit={modal.person}
                editPerson={editPerson}
            />
            <ConfirmModal
                show={modal.show === 'delete'}
                handleClose={() => dispatch({ type: 'CLOSE' })}
                confirmAction={confirmDelete}
                text={"Är du säker på att du vill ta bort " + modal.person?.name + "?"}
            />
        </div>
    );
}

import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';

export default function SimpleNameForm({ onSubmit, initialValue }) {
  const [name, setName] = useState("");

  useEffect(() => {
    if (initialValue) {
      setName(initialValue);
    }
  }, [initialValue]);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      onSubmit(name);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Namn:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={handleChange}
        className="ms-2"
        required
      />
      <Button type="submit" variant="primary" className="btn ms-3">Skicka</Button>
    </form>
  );
}

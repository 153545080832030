// utils/errorHandler.js
import { toast } from 'react-toastify';

export const handleError = (error, errorMessage = '') => {
  if (error.response && error.response.status === 401) {
    const logoutEvent = new Event('unauthorized');
    window.dispatchEvent(logoutEvent);
  } else {
    console.error(error);
    toast.error(errorMessage || error.response.data || error.message);
  }
};

import React, { useContext } from 'react';
import { Button, Spinner, ListGroup } from 'react-bootstrap';
import DataContext from "../contexts/DataContext";

export default function Schedule() {
    const {schedule, fetchSchedule, scheduleLoading, cancelScheduleRequest} = useContext(DataContext);

    const handleScheduleRequest = async () => {
        try {
            await fetchSchedule();
        } catch (error) {
            console.error(error);
        } 
    }

    const handleCancelRequest = () => {
        cancelScheduleRequest();
      }
    

    const formatTime = (dateString) => {
        let date = new Date(dateString);
        return date.toLocaleTimeString().slice(0, 5);
    }

    const formatDate = (dateString) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('sv-SE', options);
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
      }
      

    const formatPeople = (people) => {
        return people.map(p => p.name).join(', ');
    }

    const groupByDate = (rUnits) => {
        return rUnits.reduce((grouped, rUnit) => {
            const date = formatDate(rUnit.startTime);
            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(rUnit);
            return grouped;
        }, {});
    }

    const groupedSchedule = schedule ? groupByDate(schedule) : null;

    return (
        <div>
            {!scheduleLoading &&
                <Button variant="primary" type="submit" onClick={handleScheduleRequest} disabled={scheduleLoading}>
                    Generera schema
                </Button>
            }
            <p className={scheduleLoading ? "infoLarge" : ""}><i>Beroende på antalet reptillfällen tillagda (i synnerhet många i en enskild repgrupp) och konfigurationen som är inställd kan det ta en lång stund att generera ett schema. Ha tålamod!</i><br />Om du tycker det tar för lång tid avbryt förfrågan och sänk max antal schemagenereringar per repgrupp i din konfiguration.</p>
            
            <hr />
            {scheduleLoading && <>
            <div className="spinner-container">
                    <Spinner style={{ width: "6rem", height: "6rem" }} animation="border" />
            </div>
            <Button variant="danger" type="submit" onClick={handleCancelRequest}>Avbryt schemagenerering</Button>
            </>}
            {groupedSchedule && Object.keys(groupedSchedule).map(date => (
                <div key={date}>
                    <h3><u>{date}</u></h3>
                    <ListGroup>
                        {groupedSchedule[date].map((rUnit, i) => (
                            <ListGroup.Item key={i} className={rUnit.rehearsalUnit.name === "Paus" ? "paus" : ""}>
                                <strong>{formatTime(rUnit.startTime)} - {formatTime(rUnit.endTime)}</strong>
                                <p>{rUnit.rehearsalUnit.name}</p>
                                <p>{formatPeople(rUnit.rehearsalUnit.people)}</p>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            ))}
        </div>
    );
}

import React, { useContext, useReducer } from 'react';
import { Button, Typography } from '@mui/material';
import EditRehearsalUnitModal from '../modals/EditRehearsalUnitModal';
import ConfirmModal from '../modals/shared/ConfirmModal';
import DataContext from '../../contexts/DataContext';
import useAccordionStyles from '../../hooks/useAccordionStyles';
import { Delete, Edit, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

function modalReducer(state, action) {
    switch (action.type) {
        case 'SHOW_EDIT':
            return { ...state, show: 'edit' }
        case 'SHOW_DELETE':
            return { ...state, show: 'delete' }
        case 'CLOSE':
            return { ...state, show: 'none' }
        default:
            throw new Error();
    }
}

export default function RehearsalUnitView({ rehearsalUnit }) {
    const { editRehearsalUnit, deleteRehearsalUnit } = useContext(DataContext);
    const { Accordion, AccordionSummary, AccordionDetails } = useAccordionStyles();

    const StyledAccordionSummary = styled(AccordionSummary)({
        backgroundColor: 'rgba(255,255,102,0.2)',
        '&:hover': { backgroundColor: 'rgba(255, 255, 102, 0.5)' },
    });      

    const StyledAccordionDetails = styled(AccordionDetails)({
        backgroundColor: 'rgba(255,255,102,0.08)',
    });

    const [modal, dispatch] = useReducer(modalReducer, { show: 'none' });

    const showRehearsalUnit = () => {
        dispatch({ type: 'SHOW_EDIT'});
    }

    const confirmDelete = async () => {
        await deleteRehearsalUnit(rehearsalUnit.id);
        dispatch({ type: 'CLOSE' });
    };

    const showDeleteConfirm = () => {
        dispatch({ type: 'SHOW_DELETE'});
    };

    return (
        <Accordion key={rehearsalUnit.id}>
            <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${rehearsalUnit.id}-content`}
                id={`panel${rehearsalUnit.id}-header`}
            >
                <Typography className="col-4">
                    {rehearsalUnit.name} <i>({rehearsalUnit.people.length} st. medverkande)</i>
                </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <div>
                    <h4>{rehearsalUnit.name}</h4>
                    <div>Längd: {rehearsalUnit.duration} minuter</div>
                    <div>
                        Medverkande:
                        <ul>
                            {rehearsalUnit.people.map((person) => (
                                <li key={person.id}>{person.name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="d-flex">
                    <Button variant="contained" startIcon={<Delete />} color="error" onClick={() => showDeleteConfirm()}>
                        Ta bort
                    </Button>
                    <Button className="ms-2" startIcon={<Edit />} variant="contained" color="primary" onClick={() => showRehearsalUnit()}>
                        Redigera
                    </Button>
                </div>
            </StyledAccordionDetails>

            <EditRehearsalUnitModal
                show={modal.show === 'edit'}
                handleClose={() => dispatch({ type: 'CLOSE' })}
                rehearsalUnitToEdit={rehearsalUnit}
                editRehearsalUnit={editRehearsalUnit}
            />
            <ConfirmModal
                show={modal.show === 'delete'}
                handleClose={() => dispatch({ type: 'CLOSE' })}
                confirmAction={confirmDelete}
                text={"Är du säker på att du vill ta bort " + rehearsalUnit.name + "?"}
            />
        </Accordion>
    );
}
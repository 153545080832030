import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, isAuthChecked } = useContext(UserContext);
  
    if (!isAuthChecked){
        return null;
    }

  return isAuthenticated ? element : <Navigate to="/" />;
};

export default ProtectedRoute;

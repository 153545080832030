import React, { useContext, useReducer } from 'react';
import { IconButton, Tooltip, Button, useMediaQuery, createTheme, ThemeProvider, Grid } from '@mui/material';
import { ArrowDownward, ArrowUpward, DeleteForeverRounded, FileCopy, ExpandMore, Add, Edit } from '@mui/icons-material';
import DataContext from '../../contexts/DataContext';
import ConfirmModal from '../modals/shared/ConfirmModal';
import RehearsalUnitView from './RehearsalUnitView';
import useAccordionStyles from '../../hooks/useAccordionStyles';
import EditGroupModal from '../modals/EditGroupModal';
import AddRehearsalUnitModal from '../modals/AddRehearsalUnitModal';

function modalReducer(state, action) {
    switch (action.type) {
        case 'SHOW_COPY_GROUP':
            return { ...state, show: 'copy', group: action.payload }
        case 'SHOW_DELETE_GROUP':
            return { ...state, show: 'delete', group: action.payload }
        case 'CLOSE':
            return { ...state, show: 'none' }
        case 'ADD_REHEARSALUNIT':
            return { ...state, show: 'addRehearsalUnit', group: action.payload }
        case 'EDIT_GROUP':
            return { ...state, show: 'editGroup', group: action.payload }
        default:
            throw new Error();
    }
}

function Inner({ rehearsalGroups }) {
    const { copyRehearsalGroup, deleteRehearsalGroup, switchRehearsalGroups, addRehearsalUnit, editRehearsalGroup } = useContext(DataContext);
    const { Accordion, AccordionSummary, AccordionDetails } = useAccordionStyles();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));


    const [modal, dispatch] = useReducer(modalReducer, { show: 'none', group: [] });

    const handleCopy = async (event, groupId) => {
        event.stopPropagation();
        dispatch({ type: 'SHOW_COPY_GROUP', payload: groupId });
    };

    const confirmCopy = async () => {
        await copyRehearsalGroup(modal.group);
        dispatch({ type: 'CLOSE' });
    };

    const handleDeleteGroup = async (event, groupId) => {
        event.stopPropagation();
        dispatch({ type: 'SHOW_DELETE_GROUP', payload: groupId });
    };

    const handleEditGroup = async (event, group) => {
        event.stopPropagation();
        dispatch({ type: 'EDIT_GROUP', payload: group });
    }

    const confirmDeleteGroup = async () => {
        await deleteRehearsalGroup(modal.group);
        dispatch({ type: 'CLOSE' });
    };

    const handleRehearsalSwitch = (event, group1, group2) => {
        event.stopPropagation();
        switchRehearsalGroups(group1, group2);
    }

    const handleAddRehearsalUnit = (event, rehearsalGroup) => {
        event.stopPropagation();
        dispatch({ type: 'ADD_REHEARSALUNIT', payload: rehearsalGroup });
    }

    return (
        <>
    {rehearsalGroups && rehearsalGroups.map((group, index) => (
        <Accordion key={`group-${group.id}`}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`group-${group.id}-content`}
                id={`group-${group.id}-header`}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <Grid container alignItems="center">
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 style={{ marginRight: '8px' }}>{index + 1}. {group.name}</h3>
                                <div><i>({group.rehearsalUnits.length} reptillfälle{group.rehearsalUnits.length === 1 ? '' : 'n'})</i></div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <div>
                            <IconButton className='me-2' onClick={(event) => handleEditGroup(event, group)}>
                                <Tooltip title="Ändra namn på repgruppen">
                                    <Edit />
                                </Tooltip>
                            </IconButton>
                            <Tooltip title="Lägg till reptillfälle">
                                <Button variant="outlined" size='small' startIcon={<Add />} onClick={(event) => handleAddRehearsalUnit(event, rehearsalGroups[index])}>
                                    Lägg till reptillfälle
                                </Button>
                            </Tooltip>
                            <IconButton style={{ visibility: index > 0 ? 'visible' : 'hidden' }} onClick={(event) => handleRehearsalSwitch(event, rehearsalGroups[index - 1].id, group.id)}>
                                <Tooltip title="Flytta upp repgruppen">
                                    <ArrowUpward />
                                </Tooltip>
                            </IconButton>
                            <IconButton style={{ visibility: index < rehearsalGroups.length - 1 ? 'visible' : 'hidden' }} onClick={(event) => handleRehearsalSwitch(event, group.id, rehearsalGroups[index + 1].id)}>
                                <Tooltip title="Flytta ned repgruppen">
                                    <ArrowDownward />
                                </Tooltip>
                            </IconButton>
                            <IconButton onClick={(event) => handleCopy(event, group.id)}>
                                <Tooltip title="Kopiera repgruppen och lägg till kopian sist i listan">
                                    <FileCopy />
                                </Tooltip>
                            </IconButton>
                            <IconButton onClick={(event) => handleDeleteGroup(event, group.id)}>
                                <Tooltip title="Ta bort repgruppen">
                                    <DeleteForeverRounded />
                                </Tooltip>
                            </IconButton>
                        </div>
                        </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <h5>Reptillfällen i {group.name}: </h5>
                        {group.rehearsalUnits && group.rehearsalUnits.map((rUnit) => (
                            <RehearsalUnitView key={rUnit.id} rehearsalUnit={rUnit} />
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
            <AddRehearsalUnitModal
                show={modal.show === 'addRehearsalUnit'}
                handleClose={() => dispatch({ type: 'CLOSE' })}
                addRehearsalUnit={addRehearsalUnit}
                rehearsalGroup={modal.group}
            />
            <EditGroupModal
                show={modal.show === 'editGroup'}
                handleClose={() => dispatch({ type: 'CLOSE' })}
                editGroup={editRehearsalGroup}
                groupToEdit={modal.group}
            />
            <ConfirmModal
                show={modal.show === 'delete'}
                handleClose={() => dispatch({ type: 'CLOSE' })}
                confirmAction={confirmDeleteGroup}
                text={"Är du säker på att du vill ta bort repgruppen och alla reptillfällen som ligger i den?"}
            />
            <ConfirmModal
                show={modal.show === 'copy'}
                handleClose={() => dispatch({ type: 'CLOSE' })}
                confirmAction={confirmCopy}
                text={"Är du säker på att du vill kopiera repgruppen?"}
            />
        </>
    );
}

export default function RehearsalUnitsTable({ rehearsalGroups }) {
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 992,
                lg: 1280,
                xl: 1920,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Inner rehearsalGroups={rehearsalGroups} />
        </ThemeProvider>
    );
}
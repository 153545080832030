import React, { useState, useContext } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, NavbarText } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { UserContext } from '../contexts/UserContext';
import Login from './Login';
import DataContext from '../contexts/DataContext';


const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { user, handleLogout } = useContext(UserContext);
  const { clearData } = useContext(DataContext)

  const toggleNavbar = () => {
    setCollapsed(prevState => !prevState);
  }

  const collapseMenu = () => {
    setCollapsed(true);
  }

  const logOut = async () => {
    await handleLogout();
    clearData();
  }

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <NavbarBrand tag={Link} to="/">Automatisk schemaläggare</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            {user && (
              <>
                <NavItem>
                  <NavLink onClick={collapseMenu} tag={Link} className="text-dark" to="/">Start</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={collapseMenu} tag={Link} className="text-dark" to="/schedule">Generera schema</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={collapseMenu} tag={Link} className="text-dark" to="/rehearsals">Reptillfällen</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={collapseMenu} tag={Link} className="text-dark" to="/people">Personer</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={collapseMenu} tag={Link} className="text-dark" to="/configuration">Konfiguration</NavLink>
                </NavItem>
                <NavbarText>| Inloggad som {user.userName}</NavbarText>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" onClick={logOut} to="/">Logga ut</NavLink>
                </NavItem>
              </>
            )}
            {!user && (
              <NavItem>
                <Login />
              </NavItem>
            )}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}

export default NavMenu;
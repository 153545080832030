import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { ToastContainer } from 'react-toastify';
import './custom.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

  return (
    <Layout>
      <ToastContainer position="bottom-center" autoClose="2000" />
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
    </Layout>
  );
}

export default App;

import React from "react";
import SimpleNameForm from "../forms/SimpleNameForm";

export default function AddRehearsalGroup({ addGroup }) {
  const handleSubmit = (name) => {
    addGroup(name);
  };

  return (
    <div>
      <h4>Lägg till en ny repgrupp</h4>
      <SimpleNameForm onSubmit={handleSubmit} />
    </div>
  );
}
